import React from "react";
import { Box, Text, VStack } from "@chakra-ui/react";
import { useGameDetails } from "../hooks/useGameDetails";
import PlayerCard from "./PlayerCard";

interface GameConnectedPlayersProps {
  gameId: string;
}

const GameConnectedPlayers: React.FC<GameConnectedPlayersProps> = ({
  gameId,
}) => {
  const { data: game, isLoading, error } = useGameDetails(gameId);

  if (isLoading) {
    return <Text>Loading players...</Text>;
  }

  if (error) {
    return <Text>Error loading players: {(error as Error).message}</Text>;
  }

  if (!game) {
    return <Text>No game found with ID: {gameId}</Text>;
  }

  return (
    <Box>
      <Text fontWeight="bold" mb={2}>
        Connected Players:
      </Text>
      <VStack align="stretch" spacing={2}>
        {game.connectedPlayerIds?.map((playerId) => (
          <PlayerCard key={playerId} playerId={playerId} />
        ))}
      </VStack>
    </Box>
  );
};

export default GameConnectedPlayers;
