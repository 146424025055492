import React, { useState } from "react";
import { Box, Input } from "@chakra-ui/react";
import { useWebSocketContext } from "../contexts/WebSocketContext";
import FSButton from "../design/FSButton";

interface ChatInputProps {
  gameId: string;
}

const ChatInput: React.FC<ChatInputProps> = () => {
  const [message, setMessage] = useState("");
  const { sendMessage } = useWebSocketContext();

  const handleSendMessage = () => {
    if (message.trim() !== "") {
      sendMessage(message);
      setMessage("");
    }
  };

  return (
    <Box display="flex">
      <Input
        flex="1"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Type a message..."
      />
      <FSButton onClick={handleSendMessage} ml="2">
        Send
      </FSButton>
    </Box>
  );
};

export default ChatInput;
