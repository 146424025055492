import { ThemeConfig, extendTheme } from "@chakra-ui/react";

const config: ThemeConfig = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};

// example theme
export const theme = extendTheme({
  config,
  colors: {
    transparent: "transparent",
    black: "#252028",
    white: "#FDFAF2",
    gray: {
      "50": "#F3F2F1",
      "100": "#DEDCD8",
      "200": "#CAC5BF",
      "300": "#B5AFA6",
      "400": "#A0988D",
      "500": "#8B8174",
      "600": "#6F685D",
      "700": "#534E46",
      "800": "#38342E",
      "900": "#1C1A17",
    },
    purple: {
      "50": "#F3EFF5",
      "100": "#DED3E3",
      "200": "#C9B7D1",
      "300": "#B49BC0",
      "400": "#9F7FAE",
      "500": "#8A639C",
      "600": "#6E4F7D",
      "700": "#533B5E",
      "800": "#37283E",
      "900": "#1C141F",
    },
    red: {
      "50": "#FBE9E9",
      "100": "#F5C2C2",
      "200": "#EE9B9B",
      "300": "#E77373",
      "400": "#E14C4C",
      "500": "#DA2525",
      "600": "#AF1D1D",
      "700": "#831616",
      "800": "#570F0F",
      "900": "#2C0707",
    },
    yellow: {
      "50": "#FAF5EB",
      "100": "#F0E3C6",
      "200": "#E7D1A2",
      "300": "#DDBF7E",
      "400": "#D3AD59",
      "500": "#CA9B35",
      "600": "#A27C2A",
      "700": "#795D20",
      "800": "#513E15",
      "900": "#281F0B",
    },
    green: {
      "50": "#F2FAEA",
      "100": "#DBF1C6",
      "200": "#C4E8A1",
      "300": "#ACDF7C",
      "400": "#95D657",
      "500": "#7ECD32",
      "600": "#65A428",
      "700": "#4C7B1E",
      "800": "#325214",
      "900": "#19290A",
    },
  },
  fonts: {
    body: "system-ui, sans-serif",
    heading: "Georgia, serif",
    mono: "Menlo, monospace",
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "3.75rem",
    "7xl": "4.5rem",
    "8xl": "6rem",
    "9xl": "8rem",
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  lineHeights: {
    normal: "normal",
    none: 1,
    shorter: 1.25,
    short: 1.375,
    base: 1.5,
    tall: 1.625,
    taller: "2",
    "3": ".75rem",
    "4": "1rem",
    "5": "1.25rem",
    "6": "1.5rem",
    "7": "1.75rem",
    "8": "2rem",
    "9": "2.25rem",
    "10": "2.5rem",
  },
  letterSpacings: {
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0",
    wide: "0.025em",
    wider: "0.05em",
    widest: "0.1em",
  },
});
