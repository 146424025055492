import React, { useState } from "react";
import { useSubscribePush } from "../hooks/useSubscribePush";
import { getMessaging, getToken } from "firebase/messaging";
import FSButton from "../design/FSButton";

const messaging = getMessaging();

const SubscribeToPushButton: React.FC = () => {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isSubscribing, setIsSubscribing] = useState(false);

  const subscribePush = useSubscribePush();

  const handleSubscribe = async () => {
    console.log("Requesting push permission...");

    Notification.requestPermission().then(async (permission) => {
      if (permission === "granted") {
        console.log("Notification permission granted");

        try {
          setIsSubscribing(true);

          // Public key below; it's fine to leave as plain text (meant to be public)
          const token = await getToken(messaging, {
            vapidKey:
              "BCg7AuvPwquf37FQNQWarzgqDgH4sSoXGX0nKXjmWNVTZ5ScVQAJXWCCbCQg7JIIZqp63yeceTeT3JzRx6R24_U",
          });

          await subscribePush.mutateAsync({ token });
          setIsSubscribed(true);
          console.debug("Sent push token to server:", token);
        } finally {
          setIsSubscribing(false);
        }
      }
    });
  };

  return (
    <FSButton
      isLoading={isSubscribing}
      onClick={handleSubscribe}
      isDisabled={isSubscribed}
    >
      {isSubscribed ? "Subscribed!" : "Subscribe To Push"}
    </FSButton>
  );
};

export default SubscribeToPushButton;
