import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getMessaging, onMessage } from "firebase/messaging";
import { envConfig } from "./env_config";

console.log("FIREBASE_CONFIG", envConfig.FIREBASE_CONFIG);
const app = initializeApp(envConfig.FIREBASE_CONFIG);
const auth = getAuth(app);

const messaging = getMessaging();

onMessage(messaging, (payload) => {
  console.log("[foreground] Push message received:", payload);
});

export { auth };
