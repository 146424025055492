import React, { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../firebase_init";
import {
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signOut,
  User,
} from "firebase/auth";
import { envConfig } from "../env_config";

interface AuthContextValue {
  user: User | null;
  loading: boolean;
  sendSignInLink: (url: string) => Promise<void>;
  signInWithLink: (email: string, url: string) => Promise<User | null>;
  logoutUser: () => Promise<void>;
}

const AuthContext = createContext<AuthContextValue | undefined>(undefined);

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuthContext must be used within a AuthProvider");
  }
  return context;
};

const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be whitelisted in the Firebase Console.
  url: `${envConfig.FRONTEND_URL}/finishSignUp`, // Replace with your actual URL
  handleCodeInApp: true,
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  const sendSignInLink = async (email: string): Promise<void> => {
    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
  };

  const isSignInLink = (url: string): boolean => {
    return isSignInWithEmailLink(auth, url);
  };

  const signInWithLink = async (
    email: string,
    url: string,
  ): Promise<User | null> => {
    if (isSignInLink(url)) {
      try {
        const userCredential = await signInWithEmailLink(auth, email, url);
        return userCredential.user;
      } catch (error) {
        console.error("Error signing in with email link:", error);
        return null;
      }
    }
    return null;
  };

  const logoutUser = async (): Promise<void> => {
    await signOut(auth);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, loading, logoutUser, sendSignInLink, signInWithLink }}
    >
      {children}
    </AuthContext.Provider>
  );
};
