import React from "react";
import { Box, Text } from "@chakra-ui/react";
import FSButton from "../design/FSButton";
import { useWebSocketContext } from "../contexts/WebSocketContext";
import InviteAgentModal from "./InviteAgentModal";
import GameConnectedPlayers from "./GameConnectedPlayers";

interface GameStatusBarProps {
  gameId: string;
}

const GameStatusBar: React.FC<GameStatusBarProps> = ({ gameId }) => {
  const { isConnected, isLoading, error } = useWebSocketContext();
  const [isInviteModalOpen, setIsInviteModalOpen] = React.useState(false);

  return (
    <Box textAlign="center">
      <Text fontWeight="bold" color={isConnected ? "green.500" : "red.500"}>
        {isConnected
          ? "Connected"
          : isLoading
            ? "Loading..."
            : error || "Disconnected"}
      </Text>
      {isConnected && <Text fontSize={"xx-small"}>{gameId}</Text>}
      {isConnected && (
        <FSButton onClick={() => setIsInviteModalOpen(true)}>
          Invite Agent
        </FSButton>
      )}
      <InviteAgentModal
        gameId={gameId}
        isOpen={isInviteModalOpen}
        onClose={() => setIsInviteModalOpen(false)}
        key={gameId}
      />
      <GameConnectedPlayers gameId={gameId} />
    </Box>
  );
};

export default GameStatusBar;
