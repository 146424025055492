import React, { useState } from "react";
import { Text } from "@chakra-ui/react";
import LoginModal from "./LoginModal";
import { useAuthContext } from "../contexts/AuthContext";
import FSButton from "../design/FSButton";

const LoginButton: React.FC = () => {
  const { user, logoutUser, loading } = useAuthContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      {user && <Text alignSelf={"center"}>Logged in as {user.email}</Text>}
      {!user && (
        <FSButton
          colorScheme={user ? "green" : "blue"}
          onClick={() => setIsModalOpen(true)}
          isLoading={loading}
          disabled={!!user}
        >
          Log In
        </FSButton>
      )}

      {!!user && (
        <FSButton colorScheme="red" onClick={logoutUser}>
          Logout
        </FSButton>
      )}

      <LoginModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
};

export default LoginButton;
