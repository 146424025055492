import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { useCreateGame } from "../hooks/useCreateGame";
import { Game } from "../types";
import FSButton from "../design/FSButton";

interface CreateGameModalProps {
  isOpen: boolean;
  onClose: () => void;
  onGameCreated: (game: Game) => void;
}

const CreateGameModal: React.FC<CreateGameModalProps> = ({
  isOpen,
  onClose,
  onGameCreated,
}) => {
  const createGameMutation = useCreateGame();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const gameData = {
      name: formData.get("name") as string,
    };

    try {
      const createdGame = await createGameMutation.mutateAsync(gameData);
      onGameCreated(createdGame);
      onClose();
    } catch (error) {
      // Handle error, e.g., show an error message
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create New Game</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel>Game Name</FormLabel>
              <Input
                type="text"
                name="name"
                placeholder="Enter game name"
                required
              />
            </FormControl>
            <FSButton
              type="submit"
              mt={4}
              colorScheme="blue"
              disabled={createGameMutation.isLoading}
            >
              {createGameMutation.isLoading ? "Creating..." : "Create Game"}
            </FSButton>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CreateGameModal;
