import { useQuery } from "react-query";
import { Game } from "../types";
import axiosInstance from "../services/axiosInstance";

const fetchGames = async (): Promise<Game[]> => {
  const response = await axiosInstance.get(`/api/game`);
  return response.data;
};

export const useGames = () => {
  return useQuery("games", fetchGames);
};
