import { useMutation } from "react-query";
import { Game } from "../types";
import { envConfig } from "../env_config";
import axiosInstance from "../services/axiosInstance";

interface SubscribePushPayload {
  token: string;
}

const subscribePush = async (payload: SubscribePushPayload): Promise<Game> => {
  const response = await axiosInstance.post(
    `${envConfig.HTTP_BACKEND_URL}/api/push/subscribe`,
    payload,
  );
  return response.data;
};

export const useSubscribePush = () => {
  return useMutation(subscribePush);
};
