import React, { useState } from "react";
import {
  Box,
  Text,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import LoginModal from "./LoginModal";
import { useAuthContext } from "../contexts/AuthContext";
import FSButton from "../design/FSButton";

const ProfileButton: React.FC = () => {
  const { user, logoutUser, loading } = useAuthContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Box>
      {user ? (
        <Menu>
          <MenuButton
            as={Avatar}
            src={user.photoURL || undefined}
            size="sm"
            cursor="pointer"
          />
          <MenuList>
            <MenuItem>
              <Text>Logged in as {user.email}</Text>
            </MenuItem>
            <MenuItem onClick={logoutUser}>Logout</MenuItem>
          </MenuList>
        </Menu>
      ) : (
        <FSButton
          colorScheme="blue"
          onClick={() => setIsModalOpen(true)}
          isLoading={loading}
          leftIcon={<Avatar size="xs" />}
        >
          Log In
        </FSButton>
      )}

      <LoginModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </Box>
  );
};

export default ProfileButton;
