import React from "react";
import { Button, ButtonProps } from "@chakra-ui/react";

type FSButtonProps = ButtonProps;

const FSButton: React.FC<FSButtonProps> = (props) => {
  const defaultProps: ButtonProps = {
    size: "md",
    borderRadius: "full",
  };

  const mergedProps = { ...defaultProps, ...props };
  return <Button {...mergedProps}>{props.children}</Button>;
};

export default FSButton;
