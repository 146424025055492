/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { DisplayMessageData } from "../types";
import { useAuthContext } from "../contexts/AuthContext";

interface MessageProps {
  message: DisplayMessageData;
}

const MessageDisplay: React.FC<MessageProps> = ({ message }) => {
  const { user } = useAuthContext();
  const isOwnMessage = message.wsMessage.from === user?.uid;
  const color = message.isError
    ? "red.700"
    : message.isStreaming
      ? "blue.700"
      : isOwnMessage
        ? "green.800"
        : "gray.400";
  const messageLines = message.wsMessage.data?.split(/\r\n|\r|\n/) || [];

  return (
    <Box
      key={message.wsMessage.userId}
      border={1}
      p={2}
      borderRadius="md"
      bg={color}
    >
      <Text fontWeight="bold">{message.wsMessage.from}</Text>
      {messageLines.map((line: any, index: any) => (
        <Text key={index} whiteSpace="pre-wrap" minH={"1.2em"}>
          {line}
        </Text>
      ))}
      <Text fontSize="sm" color="gray.500">
        {message.wsMessage.timestamp
          ? new Date(message.wsMessage.timestamp).toLocaleString()
          : ""}
      </Text>
    </Box>
  );
};

export default MessageDisplay;
