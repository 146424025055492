import { useEffect, useState } from "react";
import { Message, WsMessage } from "@forgesaga/common/dist/types";
import { DisplayMessageData } from "../types";
import { useQueryClient } from "react-query";
import axiosInstance from "../services/axiosInstance";

export function useMessages(
  socket: WebSocket | null,
  gameId: string,
  userId: string,
) {
  const [messages, setMessages] = useState<
    Record<string, DisplayMessageData[]>
  >({});
  const queryClient = useQueryClient();

  // Historical Message fetching.
  useEffect(() => {
    const fetchHistoricalMessages = async () => {
      try {
        const response = await axiosInstance.get(
          `/api/game/${gameId}/messages`,
        );
        const historicalMessages = response.data;

        setMessages((prevMessages) => {
          const combinedMessages = [
            ...(prevMessages[gameId] || []),
            ...historicalMessages.map((message: Message) => ({
              isStreaming: false,
              done: true,
              wsMessage: {
                gameId: gameId,
                from: message.author,
                data: message.content,
                timestamp: message.timestamp,
              },
            })),
          ];

          // De-duplicate messages based on timestamp
          const uniqueMessages = combinedMessages.filter(
            (message, index, self) =>
              index ===
              self.findIndex(
                (m) => m.wsMessage.timestamp === message.wsMessage.timestamp,
              ),
          );

          // Sort messages based on timestamp (latest message at the end)
          const sortedMessages = uniqueMessages.sort(
            (a, b) => a.wsMessage.timestamp - b.wsMessage.timestamp,
          );

          return {
            ...prevMessages,
            [gameId]: sortedMessages,
          };
        });
      } catch (error) {
        console.error("Error fetching historical messages:", error);
      }
    };

    fetchHistoricalMessages();
  }, [gameId]);

  useEffect(() => {
    if (!socket) return;

    const handleMessage = (event: MessageEvent) => {
      try {
        const { action, message, gameId, type, data, from, timestamp } =
          JSON.parse(event.data) as WsMessage;
        console.log("Received message:", event.data);

        if (!gameId) {
          return;
        }

        if (action === "server-message") {
          setMessages((prevMessages) => ({
            ...prevMessages,
            [gameId]: [
              ...(prevMessages[gameId] || []),
              {
                isStreaming: false,
                done: true,
                wsMessage: {
                  gameId: gameId,
                  from: "Server",
                  data: message,
                  timestamp: timestamp,
                },
              } as DisplayMessageData,
            ],
          }));
          // Invalidate QueryClient for game details.
          queryClient.invalidateQueries(["game", gameId]);
          return;
        }

        if (from === userId) {
          return;
        }

        if (type === "delta") {
          // Incrementally build the message from data chunks
          setMessages((prevMessages) => {
            const currentGameMessages = prevMessages[gameId] || [];
            const lastMessageIndex = currentGameMessages.length - 1;
            const lastMessage = currentGameMessages[lastMessageIndex];

            if (lastMessage && lastMessage.wsMessage.gameId === gameId) {
              // Update the last message with the new data chunk
              if (!data) {
                return prevMessages;
              }

              const updatedMessage: DisplayMessageData = {
                ...lastMessage,
                wsMessage: {
                  ...lastMessage.wsMessage,
                  data: lastMessage.wsMessage.data + data,
                },
              };
              return {
                ...prevMessages,
                [gameId]: [
                  ...currentGameMessages.slice(0, lastMessageIndex),
                  updatedMessage,
                ],
              };
            } else {
              // Create a new message with the data chunk
              return {
                ...prevMessages,
                [gameId]: [
                  ...currentGameMessages,
                  {
                    wsMessage: {
                      gameId: gameId as string,
                      data: data as string,
                    },
                  } as DisplayMessageData,
                ],
              };
            }
          });
        } else if (type === "status") {
          // We either get START or DONE
          // for START we need to add a new message.
          if (data === "START") {
            setMessages((prevMessages) => ({
              ...prevMessages,
              [gameId]: [
                ...(prevMessages[gameId] || []),
                {
                  isStreaming: true,
                  done: false,
                  wsMessage: {
                    gameId: gameId,
                    data: "",
                  },
                } as DisplayMessageData,
              ],
            }));
          } else {
            // For DONE we potentially change the status of the message in the UI?
            // Set the isStreaming to false and done to true
            setMessages((prevMessages) => {
              const currentGameMessages = prevMessages[gameId] || [];
              const lastMessageIndex = currentGameMessages.length - 1;
              const lastMessage = currentGameMessages[lastMessageIndex];

              if (lastMessage && lastMessage.wsMessage.gameId === gameId) {
                const updatedMessage: DisplayMessageData = {
                  ...lastMessage,
                  isStreaming: false,
                  done: true,
                };
                return {
                  ...prevMessages,
                  [gameId]: [
                    ...currentGameMessages.slice(0, lastMessageIndex),
                    updatedMessage,
                  ],
                };
              } else {
                return prevMessages;
              }
            });
          }
        } else if (type === "error") {
          // Handle errors
          setMessages((prevMessages) => ({
            ...prevMessages,
            [gameId]: [
              ...(prevMessages[gameId] || []),
              {
                isError: true,
                isStreaming: false,
                done: true,
                wsMessage: {
                  gameId: gameId,
                  data: data as string,
                },
              } as DisplayMessageData,
            ],
          }));
        } else if (action === "player-message") {
          setMessages((prevMessages) => ({
            ...prevMessages,
            [gameId]: [
              ...(prevMessages[gameId] || []),
              {
                isStreaming: false,
                done: true,
                wsMessage: {
                  gameId: gameId,
                  from: from,
                  data: message,
                },
              } as DisplayMessageData,
            ],
          }));
        } else {
          // Handle complete messages
          setMessages((prevMessages) => ({
            ...prevMessages,
            [gameId]: [
              ...(prevMessages[gameId] || []),
              {
                isStreaming: false,
                done: true,
                wsMessage: {
                  gameId: gameId,
                  data: data as string,
                },
              } as DisplayMessageData,
            ],
          }));
        }
      } catch (error) {
        console.error("Error handling message:", error);
      }
    };

    socket.addEventListener("message", handleMessage);

    return () => {
      socket.removeEventListener("message", handleMessage);
    };
  }, [socket, gameId, userId]);

  return { messages, setMessages };
}
