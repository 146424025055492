import React, { useState } from "react";
import { Select } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useGames } from "../hooks/useGames";
import CreateGameModal from "./CreateGameModal";
import { Game } from "../types";

interface GameSelectProps {
  selectedRoomId: string;
  onRoomChange: (roomId: string) => void;
}

const GameSelect: React.FC<GameSelectProps> = ({
  selectedRoomId,
  onRoomChange,
}) => {
  const { data: rooms, isLoading, isError } = useGames();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCreateGame = (game: Game) => {
    // Handle the created game, e.g., update the rooms list or navigate to the game page
    onRoomChange(game.id);
    setIsModalOpen(false);
  };

  if (isLoading) {
    return <div>Loading rooms, please wait...</div>;
  }

  if (isError || !rooms) {
    return <div>Error fetching rooms.</div>;
  }

  return (
    <>
      <Select
        value={selectedRoomId}
        onChange={(e) => {
          if (e.target.value === "create") {
            setIsModalOpen(true);
          } else {
            onRoomChange(e.target.value);
          }
        }}
      >
        <option value="">Select a room</option>
        {rooms.map((room) => (
          <option key={room.id} value={room.id}>
            <Link to={`/?roomId=${room.id}`}>{room.name}</Link>
          </option>
        ))}
        <option value="create">Create New Game</option>
      </Select>

      <CreateGameModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onGameCreated={handleCreateGame}
      />
    </>
  );
};

export default GameSelect;
