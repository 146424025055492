// LoginModal.tsx

import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import { useAuthContext } from "../contexts/AuthContext";
import FSButton from "../design/FSButton";

interface LoginModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const LoginModal: React.FC<LoginModalProps> = ({ isOpen, onClose }) => {
  const { sendSignInLink } = useAuthContext();

  // Grab email default from local stoarge
  const [email, setEmail] = useState(localStorage.getItem("email") || "");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSendSignInLink = async () => {
    try {
      await sendSignInLink(email);
      // Save email in local storage
      localStorage.setItem("email", email);
      setSuccess(true);
      setError("");
    } catch (error) {
      setError("Failed to send sign-in link. Please try again.");
      setSuccess(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Login with Email Link</ModalHeader>
        <ModalBody>
          {error && <Text color="red.500">{error}</Text>}
          {success && (
            <Text color="green.500">
              Sign-in link sent! Please check your email.
            </Text>
          )}
          <FormControl id="email" mt={4}>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <FSButton colorScheme="blue" mr={3} onClick={handleSendSignInLink}>
            Send Sign-In Link
          </FSButton>
          <FSButton onClick={onClose}>Cancel</FSButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LoginModal;
