import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import { useInviteAgent } from "../hooks/useInviteAgent";
import { CreateAgentInviteRequest } from "@forgesaga/common/dist/types";
import FSButton from "../design/FSButton";

interface ModalProps {
  gameId: string;
  isOpen: boolean;
  onClose: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConfigForm: React.FC<{ onChange: (config: any) => void }> = ({
  onChange,
}) => {
  const [config, setConfig] = React.useState({
    name: "Gygax",
    revision: "v1",
    provider: "openai",
    model: "gpt-3.5-turbo-0125",
    tool_names: ["dice_roll"],
    context_size_tokens: 1000,
    provider_metadata: {
      temperature: 0.5,
      max_tokens: 500,
    },
    system_prompt: "",
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (field: string, value: any) => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      [field]: value,
    }));
    onChange(config);
  };

  return (
    <>
      <FormControl>
        <FormLabel>Name</FormLabel>
        <Input
          type="text"
          value={config.name}
          onChange={(e) => handleChange("name", e.target.value)}
        />
      </FormControl>
      <FormControl mt={4}>
        <FormLabel>Revision</FormLabel>
        <Input
          type="text"
          value={config.revision}
          onChange={(e) => handleChange("revision", e.target.value)}
        />
      </FormControl>
      <FormControl mt={4}>
        <FormLabel>Model</FormLabel>
        <Select
          value={config.model}
          onChange={(e) => handleChange("model", e.target.value)}
        >
          <option value="gpt-3.5-turbo-0125">gpt-3.5-turbo-0125</option>
          {/* Add more model options as needed */}
        </Select>
      </FormControl>
      <FormControl mt={4}>
        <FormLabel>Tool Names</FormLabel>
        <Input
          type="text"
          value={config.tool_names.join(",")}
          onChange={(e) =>
            handleChange("tool_names", e.target.value.split(","))
          }
        />
      </FormControl>
      <FormControl mt={4}>
        <FormLabel>Context Size Tokens</FormLabel>
        <NumberInput
          value={config.context_size_tokens}
          onChange={(value) =>
            handleChange("context_size_tokens", Number(value))
          }
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>
      <FormControl mt={4}>
        <FormLabel>Temperature</FormLabel>
        <NumberInput
          value={config.provider_metadata.temperature}
          onChange={(value) =>
            handleChange("provider_metadata", {
              ...config.provider_metadata,
              temperature: Number(value),
            })
          }
          step={0.1}
          min={0}
          max={1}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>
      <FormControl mt={4}>
        <FormLabel>Max Tokens</FormLabel>
        <NumberInput
          value={config.provider_metadata.max_tokens}
          onChange={(value) =>
            handleChange("provider_metadata", {
              ...config.provider_metadata,
              max_tokens: Number(value),
            })
          }
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>
      <FormControl mt={4}>
        <FormLabel>System Prompt</FormLabel>
        <Textarea
          value={config.system_prompt}
          onChange={(e) => handleChange("system_prompt", e.target.value)}
        />
      </FormControl>
    </>
  );
};

const InviteAgentModal: React.FC<ModalProps> = ({
  gameId,
  isOpen,
  onClose,
}) => {
  const inviteAgentMutation = useInviteAgent();
  const [config, setConfig] = React.useState({});
  const [llmClientWebhook, setLlmClientWebhook] = React.useState(
    "http://localhost:3030/connect",
  );
  const [botId, setBotId] = React.useState(
    Math.random().toString(36).substring(2, 8),
  );

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const gameData = {
      gameId: gameId,
      botId: botId,
      llmClientWebhook: llmClientWebhook,
      config: config,
    } as CreateAgentInviteRequest;

    try {
      await inviteAgentMutation.mutateAsync(gameData);
      // TODO: Show toast?
      onClose();
    } catch (error) {
      // Handle error, e.g., show an error message
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Invite Agent</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel>Game ID</FormLabel>
              <Input type="text" name="gameId" value={gameId} readOnly />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Bot ID</FormLabel>
              <Input
                type="text"
                name="botId"
                placeholder="Enter bot ID"
                value={botId}
                onChange={(e) => setBotId(e.target.value)}
                required
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Config</FormLabel>
              <ConfigForm onChange={(config) => setConfig(config)} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>LLM Client Webhook</FormLabel>
              <Input
                type="text"
                name="llmClientWebhook"
                placeholder="Enter LLM client webhook URL"
                value={llmClientWebhook}
                onChange={(e) => setLlmClientWebhook(e.target.value)}
                required
              />
            </FormControl>
            <FSButton
              type="submit"
              mt={4}
              colorScheme="blue"
              disabled={inviteAgentMutation.isLoading}
            >
              {inviteAgentMutation.isLoading ? "Inviting..." : "Invite Agent"}
            </FSButton>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default InviteAgentModal;
