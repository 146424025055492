import React from "react";
import { Box, Text } from "@chakra-ui/react";

interface PlayerCardProps {
  playerId: string;
}

const PlayerCard: React.FC<PlayerCardProps> = ({ playerId }) => {
  // Placeholder component for rendering the player card
  return (
    <Box borderWidth={1} borderRadius="md" p={2}>
      <Text>Player ID: {playerId}</Text>
      {/* Add more player details or customize the player card */}
    </Box>
  );
};

export default PlayerCard;
