import { useQuery } from "react-query";
import { Game } from "../types";
import axiosInstance from "../services/axiosInstance";

const fetchGame = async (gameId: string): Promise<Game> => {
  const response = await axiosInstance.get(`/api/game/${gameId}`);
  return response.data;
};

export const useGameDetails = (gameId: string) => {
  return useQuery(["game", gameId], () => fetchGame(gameId));
};
