import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ChatWindow from "./components/ChatWindow";
import { QueryClient, QueryClientProvider } from "react-query";
import FinishSignUp from "./components/FinishSignUp";
import { AuthProvider } from "./contexts/AuthContext";
import { theme } from "./design/theme";

const App: React.FC = () => {
  const queryClient = new QueryClient();

  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <Router>
            <Routes>
              <Route path="/" element={<ChatWindow />} />
              <Route path="/finishSignUp" element={<FinishSignUp />} />
            </Routes>
          </Router>
        </AuthProvider>
      </QueryClientProvider>
    </ChakraProvider>
  );
};

export default App;
