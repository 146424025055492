import React from "react";
import { Box, VStack, Text, Container, Flex } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import GameSelect from "./GameSelect";
import MessageHistory from "./MessageHistory";
import ChatInput from "./ChatInput";
import { WebSocketProvider } from "../contexts/WebSocketContext";
import LoginButton from "./LoginButton";
import { useAuthContext } from "../contexts/AuthContext";
import SubscribeToPushButton from "./SubscribeToPushButton";
import GameStatusBar from "./GameStatusBar";
import ProfileButton from "./ProfileButton";

const ChatWindow: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const gameId = searchParams.get("gameId") || "";
  const { user } = useAuthContext();

  const handleGameChange = (selectedRoomId: string) => {
    navigate(`?gameId=${selectedRoomId}`);
  };

  if (!user) {
    return (
      <Box
        height="100vh"
        display="flex"
        flexDir={"column"}
        gap={4}
        justifyContent="center"
        alignItems="center"
      >
        <Text fontSize="xl">Please log in to continue</Text>
        <LoginButton />
      </Box>
    );
  }

  return (
    <WebSocketProvider gameId={gameId} userId={user.uid}>
      <ChatWindowContent gameId={gameId} onGameChange={handleGameChange} />
    </WebSocketProvider>
  );
};

const ChatWindowContent: React.FC<{
  gameId: string;
  onGameChange: (gameId: string) => void;
}> = ({ gameId, onGameChange }) => {
  const { user } = useAuthContext();

  return (
    <Container h="100vh" p={0}>
      <VStack spacing={4} h="100%" p={4}>
        <Flex w="100%" gap={3} direction={"row"}>
          <GameSelect selectedRoomId={gameId} onRoomChange={onGameChange} />
          <ProfileButton />
        </Flex>
        <GameStatusBar gameId={gameId} />
        <Box flex={1} w="100%" overflowY="auto">
          <MessageHistory gameId={gameId} />
        </Box>
        <Flex w="100%" gap={3} direction={"column"}>
          <ChatInput gameId={gameId} />
          {!!user && <SubscribeToPushButton />}
        </Flex>
      </VStack>
    </Container>
  );
};

export default ChatWindow;
