const common_host_url = "localhost";

export const envConfig = {
  FRONTEND_URL:
    process.env.REACT_APP_FRONTEND_URL || "http://" + common_host_url + ":3000",
  HTTP_BACKEND_URL:
    process.env.REACT_APP_HTTP_BACKEND_URL ||
    "http://" + common_host_url + ":4500",
  WS_BACKEND_URL:
    process.env.REACT_APP_WS_BACKEND_URL || "ws://" + common_host_url + ":4600",
  FIREBASE_CONFIG: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  },
};
