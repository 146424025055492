import { useEffect, useState } from "react";
import { envConfig } from "../env_config";
import { WsMessage } from "@forgesaga/common/dist/types";
import { useQueryClient } from "react-query";

export function useWebSocket(gameId: string, userId: string) {
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!gameId) return;

    const newSocket = new WebSocket(`${envConfig.WS_BACKEND_URL}/ws/game`);
    setIsLoading(true);
    setError(null);

    // eslint-disable-next-line prefer-const
    let timeoutId: NodeJS.Timeout;

    const handleOpen = () => {
      console.log("WebSocket connected");
      clearTimeout(timeoutId);
      setTimeout(() => {
        sendMessage(
          {
            action: "join-game",
            userId: userId,
            gameId: gameId,
            type: "status",
          },
          newSocket,
        );
        queryClient.invalidateQueries(["game", gameId]);
        setIsConnected(true);
        setIsLoading(false);
      }, 100);
    };

    const handleClose = () => {
      console.log("WebSocket disconnected");
      setIsConnected(false);
    };

    const handleTimeout = () => {
      console.error("WebSocket connection timed out");
      setError("Connection timed out");
      setIsLoading(false);
      newSocket.close();
    };

    newSocket.onopen = handleOpen;
    newSocket.onclose = handleClose;

    // Set up a timeout of 10 seconds to connect
    timeoutId = setTimeout(handleTimeout, 10000);

    setSocket(newSocket);

    return () => {
      clearTimeout(timeoutId);
      newSocket.close();
    };
  }, [gameId]);

  const sendMessage = (message: WsMessage, tmp_socket?: WebSocket) => {
    const this_socket = tmp_socket || socket;
    if (this_socket && this_socket.readyState === WebSocket.OPEN) {
      this_socket.send(JSON.stringify(message));
    } else {
      console.error("WebSocket not connected");
    }
  };

  return { socket, isConnected, isLoading, error, sendMessage };
}
