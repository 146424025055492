import React, { createContext, useContext } from "react";
import { DisplayMessageData } from "../types";
import { useMessages } from "../hooks/useMessages";
import { useWebSocket } from "../hooks/useWebSocket";

interface WebSocketContextValue {
  socket: WebSocket | null;
  messages: DisplayMessageData[];
  sendMessage: (content: string) => void;
  isConnected: boolean;
  isLoading: boolean;
  error: string | null;
}

const WebSocketContext = createContext<WebSocketContextValue | undefined>(
  undefined,
);

export const useWebSocketContext = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error(
      "useWebSocketContext must be used within a WebSocketProvider",
    );
  }
  return context;
};

interface WebSocketProviderProps {
  gameId: string;
  userId: string;
  children: React.ReactNode;
}

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({
  gameId,
  userId,
  children,
}) => {
  const { socket, isConnected, isLoading, error, sendMessage } = useWebSocket(
    gameId,
    userId,
  );
  const { messages, setMessages } = useMessages(socket, gameId, userId);

  const value: WebSocketContextValue = {
    socket,
    messages: messages[gameId] || [],
    sendMessage: (content: string) => {
      sendMessage({
        action: "send-message",
        gameId,
        data: content,
      });
      // TODO: Add our message to the messages list somehow.
      const newMessage: DisplayMessageData = {
        isStreaming: false,
        isError: false,
        done: false,
        wsMessage: {
          action: "send-message",
          gameId,
          from: userId,
          data: content,
        },
      };
      setMessages((prevMessages) => ({
        ...prevMessages,
        [gameId]: [...(prevMessages[gameId] || []), newMessage],
      }));
    },
    isConnected,
    isLoading,
    error,
  };

  return (
    <WebSocketContext.Provider value={value}>
      {children}
    </WebSocketContext.Provider>
  );
};
