// FinishSignUp.tsx

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Text, Spinner } from "@chakra-ui/react";
import { useAuthContext } from "../contexts/AuthContext";

const FinishSignUp: React.FC = () => {
  const { signInWithLink } = useAuthContext();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const handleSignIn = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const signInEmail = localStorage.getItem("email");
        const oobCode = urlParams.get("oobCode");

        if (signInEmail && oobCode) {
          const signInLink = window.location.href;

          setEmail(signInEmail);
          const user = await signInWithLink(signInEmail, signInLink);

          if (user) {
            navigate("/"); // Redirect to the dashboard
          } else {
            setError("Failed to sign in. Please try again.");
          }
        } else {
          setError("Missing email or oobCode in the URL.");
        }
      } catch (error) {
        setError("An error occurred during sign-in.");
      } finally {
        setIsLoading(false);
      }
    };

    handleSignIn();
  }, [navigate]);

  if (isLoading) {
    return (
      <Box textAlign="center" mt={8}>
        <Spinner size="xl" />
        <Text mt={4}>Signing in...</Text>
      </Box>
    );
  }

  if (error) {
    return (
      <Box textAlign="center" mt={8}>
        <Text color="red.500">{error}</Text>
      </Box>
    );
  }

  return (
    <Box textAlign="center" mt={8}>
      <Text>Sign-in successful!</Text>
      <Text>Email: {email}</Text>
    </Box>
  );
};

export default FinishSignUp;
