import { useMutation } from "react-query";
import { Game } from "../types";
import { CreateAgentInviteRequest } from "@forgesaga/common/dist/types";
import axiosInstance from "../services/axiosInstance";

const inviteAgent = async (
  gameData: CreateAgentInviteRequest,
): Promise<Game> => {
  const response = await axiosInstance.post(`/api/game/invite_agent`, gameData);
  return response.data;
};

export const useInviteAgent = () => {
  return useMutation(inviteAgent, {
    onSuccess: () => {
      // ???
    },
  });
};
