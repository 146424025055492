import { useMutation, useQueryClient } from "react-query";
import { Game, CreateGameRequest } from "../types";
import axiosInstance from "../services/axiosInstance";

const createGame = async (gameData: CreateGameRequest): Promise<Game> => {
  const response = await axiosInstance.post(`/api/game`, gameData);
  return response.data;
};

export const useCreateGame = () => {
  const queryClient = useQueryClient();

  return useMutation(createGame, {
    onSuccess: () => {
      queryClient.invalidateQueries("games");
    },
  });
};
