import React from "react";
import { Flex } from "@chakra-ui/react";
import MessageDisplay from "./MessageDisplay";
import { useWebSocketContext } from "../contexts/WebSocketContext";

interface MessageHistoryProps {
  gameId: string;
}

const MessageHistory: React.FC<MessageHistoryProps> = () => {
  const { messages } = useWebSocketContext();

  return (
    <Flex
      overflowX="auto"
      flexGrow="1"
      display={"flex"}
      gap={2}
      flexDirection={"column-reverse"}
    >
      {[...messages].reverse().map((message, index) => (
        <MessageDisplay key={index} message={message} />
      ))}
    </Flex>
  );
};

export default MessageHistory;
