import axios from "axios";
import { getToken } from "./authService";
import { envConfig } from "../env_config";

const axiosInstance = axios.create({
  baseURL: envConfig.HTTP_BACKEND_URL,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = await getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default axiosInstance;
